








































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import { getTaxes } from '@/lib/tax'
import { Tax, TaxCollectionEnum } from '../../../../../shared/types/types'
import { getTaxValue } from '@/lib/utils'

export default Vue.extend({
  name: 'Taxes',
  components: {
    NavigateBack,
  },
  data() {
    return {
      taxes: [] as Tax[],
      isLoading: false,
    }
  },
  computed: {
    hasNoTaxes(): boolean {
      return !this.isLoading && !this.taxes.length
    },
  },
  async created() {
    this.isLoading = true

    this.taxes = await getTaxes()

    this.isLoading = false
  },
  methods: {
    /**
     * Get tax collection type
     *
     * @param tax - the tax object
     */
    getTaxCollectionName(tax: Tax) {
      if (tax.collectionType === TaxCollectionEnum.AllProducts) {
        return 'All Products'
      }

      if (tax.collectionType === TaxCollectionEnum.SelectedProductVariants) {
        return 'Specific Products'
      }
    },
    /**
     * Get classes for tax collection label
     *
     * @param tax - the tax object
     */
    getTaxClasses(tax: Tax) {
      let baseClasses = {
        'px-2': true,
        'rounded-md': true,
        'text-black-500': true,
        'mr-4': true,
        'text-sm': true,
      }

      if (tax.collectionType === TaxCollectionEnum.AllProducts) {
        return {
          ...baseClasses,
          'bg-green-400': true,
        }
      }

      if (tax.collectionType === TaxCollectionEnum.SelectedProductVariants) {
        return {
          ...baseClasses,
          'bg-yellow-400': true,
        }
      }
    },
    /**
     * Get tax route
     *
     * @param tax - the tax object
     */
    getTaxRoute(tax: Tax) {
      return `/settings/taxes/${tax.taxId}`
    },
    getTaxValue,
  },
})
