








import Vue from 'vue'

export default Vue.extend({
  name: 'NavigateBack',
  props: {
    toUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    navigateBack() {
      this.$router.push(this.toUrl)
    },
  },
})
