import gql from 'graphql-tag'

export const UPDATE_TAX = gql`
  mutation UpdateTax($input: UpdateTaxInput!) {
    updateTax(input: $input) {
      taxId
      title
      type
      percentage
      flatRate {
        amount
        currency
      }
      currency
      collectionType
      productVariants {
        taxId
        productId
        variantId
        isActive
      }
      excludedProductVariants {
        productId
        variantId
      }
    }
  }
`

export const CREATE_EXCLUDED_PRODUCT_VARIANT = gql`
  mutation CreateExludedProductVariant(
    $input: CreateExcludedTaxProductVariantInput!
  ) {
    createExcludedTaxProductVariant(input: $input) {
      productId
      variantId
    }
  }
`

export const DELETE_EXCLUDED_PRODUCT_VARIANT = gql`
  mutation DeleteExludedProductVariant(
    $input: DeleteExcludedTaxProductVariantInput!
  ) {
    deleteExcludedTaxProductVariant(input: $input) {
      productId
      variantId
    }
  }
`

export const CREATE_TAX_PRODUCT_VARIANT = gql`
  mutation CreateTaxProductVariant(
    $taxId: ID!
    $input: CreateTaxProductVariantInput!
  ) {
    createTaxProductVariant(input: $input, taxId: $taxId) {
      productId
      variantId
      isActive
    }
  }
`
export const DELETE_TAX_PRODUCT_VARIANT = gql`
  mutation DeleteTaxProductVariant($input: DeleteTaxProductVariantInput!) {
    deleteTaxProductVariant(input: $input) {
      productId
      variantId
    }
  }
`

export const CREATE_TAX = gql`
  mutation CreateTax($input: CreateTaxInput!) {
    createTax(input: $input) {
      taxId
      title
      type
      percentage
      flatRate {
        amount
        currency
      }
      currency
      collectionType
      productVariants {
        taxId
        productId
        variantId
        isActive
      }
      excludedProductVariants {
        productId
        variantId
      }
    }
  }
`

export const DELETE_TAX = gql`
  mutation DeleteTax($taxId: ID!) {
    deleteTax(taxId: $taxId) {
      isDeleteSuccess
    }
  }
`

export const UPDATE_TAX_CURRENCIES = gql`
  mutation UpdateTaxCurrencies($taxId: ID!, $input: [TaxCurrenciesInput!]!) {
    updateTaxCurrencies(taxId: $taxId, input: $input) {
      currencies {
        amount
        currency
      }
    }
  }
`

export const SYNC_TAX_CURRENCIES = gql`
  mutation SyncTaxCurrencies($taxId: ID!) {
    syncTaxCurrencies(taxId: $taxId) {
      taxId
    }
  }
`
