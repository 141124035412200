import {
  CREATE_EXCLUDED_PRODUCT_VARIANT,
  CREATE_TAX,
  CREATE_TAX_PRODUCT_VARIANT,
  DELETE_EXCLUDED_PRODUCT_VARIANT,
  DELETE_TAX,
  DELETE_TAX_PRODUCT_VARIANT,
  SYNC_TAX_CURRENCIES,
  UPDATE_TAX,
  UPDATE_TAX_CURRENCIES,
} from '@/graphql/mutation/tax'
import {
  GET_TAX,
  GET_TAXES,
  GET_TAXES_FOR_PRODUCT_VARIANT,
  GET_TAX_CURRENCIES,
} from '@/graphql/query/tax'
import apolloClient from '@/services/apollo'
import {
  CreateExcludedTaxProductVariantInput,
  CreateTaxInput,
  DeleteExcludedTaxProductVariantInput,
  GetTaxesForProductVariantResponse,
  GetTaxesResponse,
  Maybe,
  MutationCreateExcludedTaxProductVariantArgs,
  MutationCreateTaxArgs,
  MutationCreateTaxProductVariantArgs,
  MutationDeleteExcludedTaxProductVariantArgs,
  MutationDeleteTaxArgs,
  MutationDeleteTaxProductVariantArgs,
  MutationUpdateTaxArgs,
  QueryGetTaxArgs,
  QueryGetTaxesForProductVariantArgs,
  QueryGetTaxesArgs,
  TaxCollectionEnum,
  Tax,
  TaxProductVariant,
  UpdateTaxInput,
  TaxCurrenciesResponse,
  TaxCurrenciesInput,
  QueryGetTaxCurrenciesArgs,
  MutationUpdateTaxCurrenciesArgs,
  MutationSyncTaxCurrenciesArgs,
} from '../../../shared/types/types'

interface GetTaxesQueryResponse {
  getTaxes: Maybe<GetTaxesResponse>
}

interface GetTaxesForProductVariantQueryResponse {
  getTaxesForProductVariant: Maybe<GetTaxesForProductVariantResponse>
}

interface CreateTaxMutationResponse {
  createTax: Maybe<Tax>
}

interface UpdateTaxMutationResponse {
  updateTax: Maybe<Tax>
}

interface CreateExcludeProductVariantMutationResult {
  createExcludeProductVariant: Maybe<TaxProductVariant>
}

interface DeleteExcludeProductVariantMutationResult {
  deleteExcludeProductVariant: Maybe<TaxProductVariant>
}

interface GetTaxQueryResponse {
  getTax: Maybe<Tax>
}

interface CreateTaxProductVariantMutationResult {
  createTaxProductVariant: Maybe<TaxProductVariant>
}

interface DeleteTaxProductVariantMutationResult {
  deleteTaxProductVariant: Maybe<TaxProductVariant>
}

export const getTaxes = async (
  collectionType?: TaxCollectionEnum
): Promise<Tax[]> => {
  const { data } = await apolloClient.query<
    GetTaxesQueryResponse,
    QueryGetTaxesArgs
  >({
    query: GET_TAXES,
    ...(collectionType && {
      variables: {
        collectionType,
      },
    }),
  })

  return data.getTaxes?.taxes || []
}

export const getTaxesForProductVariant = async (
  productId: string,
  variantId: string
): Promise<Tax[]> => {
  const { data } = await apolloClient.query<
    GetTaxesForProductVariantQueryResponse,
    QueryGetTaxesForProductVariantArgs
  >({
    query: GET_TAXES_FOR_PRODUCT_VARIANT,
    variables: {
      productId,
      variantId,
    },
    fetchPolicy: 'network-only',
  })

  return data.getTaxesForProductVariant?.taxes || []
}

export const createTax = async (
  tax: CreateTaxInput
): Promise<Maybe<Tax> | undefined> => {
  const { data } = await apolloClient.mutate<
    CreateTaxMutationResponse,
    MutationCreateTaxArgs
  >({
    mutation: CREATE_TAX,
    variables: {
      input: tax,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    update(
      cache: { modify: (data: { id: string; fields: any }) => void },
      { data }: { data: { createTax: Record<string, any> } }
    ) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          getTaxes(cacheData: Record<string, any>) {
            return {
              ...cacheData,
              taxes: [...cacheData.taxes, data?.createTax],
            }
          },
        },
      })
    },
  })

  return data?.createTax
}

export const updateTax = async (
  tax: UpdateTaxInput
): Promise<Maybe<Tax> | undefined> => {
  const { data } = await apolloClient.mutate<
    UpdateTaxMutationResponse,
    MutationUpdateTaxArgs
  >({
    mutation: UPDATE_TAX,
    variables: {
      input: tax,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    update(
      cache: Record<string, any>,
      { data }: { data: { updateTax: Record<string, any> } }
    ) {
      cache.writeQuery({
        query: GET_TAX,
        variables: {
          input: tax,
        },
        data: data?.updateTax,
      })
    },
  })

  return data?.updateTax
}

export const createExcludeProductVariant = async (
  taxProductVariant: CreateExcludedTaxProductVariantInput
): Promise<Maybe<TaxProductVariant> | undefined> => {
  const { data } = await apolloClient.mutate<
    CreateExcludeProductVariantMutationResult,
    MutationCreateExcludedTaxProductVariantArgs
  >({
    mutation: CREATE_EXCLUDED_PRODUCT_VARIANT,
    variables: {
      input: taxProductVariant,
    },
  })

  return data?.createExcludeProductVariant
}

export const deleteExcludeProductVariant = async (
  taxProductVariant: DeleteExcludedTaxProductVariantInput
): Promise<Maybe<TaxProductVariant> | undefined> => {
  const { data } = await apolloClient.mutate<
    DeleteExcludeProductVariantMutationResult,
    MutationDeleteExcludedTaxProductVariantArgs
  >({
    mutation: DELETE_EXCLUDED_PRODUCT_VARIANT,
    variables: {
      input: taxProductVariant,
    },
  })

  return data?.deleteExcludeProductVariant
}

export const getTax = async (taxId: string): Promise<Maybe<Tax>> => {
  const { data } = await apolloClient.query<
    GetTaxQueryResponse,
    QueryGetTaxArgs
  >({
    query: GET_TAX,
    variables: {
      taxId,
    },
  })

  return data.getTax && JSON.parse(JSON.stringify(data.getTax))
}

export const createTaxProductVariant = async ({
  taxId,
  productId,
  variantId,
}: {
  taxId: string
  productId: string
  variantId: string
}): Promise<Maybe<TaxProductVariant> | undefined> => {
  const { data } = await apolloClient.mutate<
    CreateTaxProductVariantMutationResult,
    MutationCreateTaxProductVariantArgs
  >({
    mutation: CREATE_TAX_PRODUCT_VARIANT,
    variables: {
      taxId,
      input: {
        productId,
        variantId,
        isActive: true,
      },
    },
  })

  return data?.createTaxProductVariant
}

export const deleteTaxProductVariant = async ({
  taxId,
  productId,
  variantId,
}: {
  taxId: string
  productId: string
  variantId: string
}): Promise<Maybe<TaxProductVariant> | undefined> => {
  const { data } = await apolloClient.mutate<
    DeleteTaxProductVariantMutationResult,
    MutationDeleteTaxProductVariantArgs
  >({
    mutation: DELETE_TAX_PRODUCT_VARIANT,
    variables: {
      input: {
        taxId,
        productId,
        variantId,
      },
    },
  })

  return data?.deleteTaxProductVariant
}

export const deleteTax = (taxId: string): Promise<any> => {
  return apolloClient.mutate<void, MutationDeleteTaxArgs>({
    mutation: DELETE_TAX,
    variables: {
      taxId,
    },
    update(cache: Record<string, any>) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          getTaxes(
            cacheData: { taxes: Record<string, any>[] },
            { readField }: { readField: (id: string, ref: any) => string }
          ) {
            const taxes = cacheData.taxes.filter(
              (taxRef: Record<string, any>) => {
                return readField('taxId', taxRef) !== taxId
              }
            )

            return {
              ...cacheData,
              taxes,
            }
          },
        },
      })
    },
  })
}

export const getTaxCurrencies = async (
  taxId: string
): Promise<TaxCurrenciesResponse> => {
  const { data } = await apolloClient.query<
    { getTaxCurrencies: TaxCurrenciesResponse },
    QueryGetTaxCurrenciesArgs
  >({
    query: GET_TAX_CURRENCIES,
    variables: {
      taxId,
    },
  })

  return data.getTaxCurrencies
}

export const updateTaxCurrencies = async (
  taxId: string,
  input: TaxCurrenciesInput[]
): Promise<TaxCurrenciesResponse | null | undefined> => {
  const { data } = await apolloClient.mutate<
    { updateTaxCurrencies: TaxCurrenciesResponse | null | undefined },
    MutationUpdateTaxCurrenciesArgs
  >({
    mutation: UPDATE_TAX_CURRENCIES,
    variables: {
      taxId,
      input,
    },
  })

  return data?.updateTaxCurrencies
}

export const syncTaxCurrencies = async (
  taxId: string
): Promise<Tax[] | null | undefined> => {
  const { data } = await apolloClient.mutate<
    { syncTaxCurrencies: Tax[] | null | undefined },
    MutationSyncTaxCurrenciesArgs
  >({
    mutation: SYNC_TAX_CURRENCIES,
    variables: {
      taxId,
    },
  })

  return data?.syncTaxCurrencies
}
