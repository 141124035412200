import gql from 'graphql-tag'

export const GET_TAX = gql`
  query GetTax($taxId: String!) {
    getTax(taxId: $taxId) {
      taxId
      title
      type
      percentage
      flatRate {
        amount
        currency
      }
      currency
      collectionType
      productVariants {
        taxId
        productId
        variantId
        isActive
        product {
          title
        }
        variant {
          title
          imageUrl
        }
      }
    }
  }
`

export const GET_TAXES = gql`
  query GetTaxes($collectionType: TaxCollectionEnum) {
    getTaxes(collectionType: $collectionType) {
      taxes {
        taxId
        title
        type
        percentage
        flatRate {
          amount
          currency
        }
        currency
        collectionType
        productVariants {
          taxId
          productId
          variantId
          isActive
        }
      }
    }
  }
`

export const GET_TAXES_FOR_PRODUCT_VARIANT = gql`
  query GetTaxesForProductVariant($productId: ID!, $variantId: ID!) {
    getTaxesForProductVariant(productId: $productId, variantId: $variantId) {
      taxes {
        taxId
        title
        type
        percentage
        flatRate {
          amount
          currency
        }
        currency
        collectionType
        productVariants {
          productId
          variantId
          isActive
        }
        excludedProductVariants {
          productId
          variantId
        }
      }
    }
  }
`

export const GET_TAX_CURRENCIES = gql`
  query getTaxCurrencies($taxId: ID!) {
    getTaxCurrencies(taxId: $taxId) {
      currencies {
        amount
        currency
      }
    }
  }
`
